import React from 'react'


export default function () {
  return(
    <div className="contact-page">
      <div className="top-banner" style={{
        backgroundImage: "url(https://images.unsplash.com/photo-1551763336-d71ed8103d8d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80)"}}>
        <div className="text">
          <h1>About Us</h1>
        </div>
      </div>



      <div data-packed="false" data-min-height={171} classname="txtNew" id="comp-jryq14uy" ><p classname="font_8"><span style={{color: '#000000'}}><span style={{fontWeight: 'bold'}}>Tonkotsu Ramen:</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>A&nbsp;<span style={{textDecoration: 'underline'}}><a href="https://en.wikipedia.org/wiki/Ramen" target="_blank" data-content="https://en.wikipedia.org/wiki/Ramen" data-type="external" rel="noopener">ramen</a></span>&nbsp;dish that originated in&nbsp;<span style={{textDecoration: 'underline'}}><a href="https://en.wikipedia.org/wiki/Fukuoka" target="_blank" data-content="https://en.wikipedia.org/wiki/Fukuoka" data-type="external" rel="noopener">Fukuoka</a></span>,&nbsp;<span style={{textDecoration: 'underline'}}><a href="https://en.wikipedia.org/wiki/Fukuoka_Prefecture" target="_blank" data-content="https://en.wikipedia.org/wiki/Fukuoka_Prefecture" data-type="external" rel="noopener">Fukuoka Prefecture</a></span>&nbsp;on the&nbsp;<span style={{textDecoration: 'underline'}}><a href="https://en.wikipedia.org/wiki/Kyushu" target="_blank" data-content="https://en.wikipedia.org/wiki/Kyushu" data-type="external" rel="noopener">Kyushu</a></span>&nbsp;island of Japan, and it is a specialty dish in both Fukuoka and Kyushu. The soup broth is based on pork bones and other ingredients, which are typically boiled for several hours, and the dish is traditionally topped with sliced pork belly and served with ramen noodles that are hard in the center. In&nbsp;<span style={{textDecoration: 'underline'}}><a href="https://en.wikipedia.org/wiki/Fukuoka" target="_blank" data-content="https://en.wikipedia.org/wiki/Fukuoka" data-type="external" rel="noopener">Fukuoka</a></span>, Japan, tonkotsu ramen is referred to as&nbsp;Hakata ramen</span></p>
         <p classname="font_8"><span style={{color: '#000000'}}><span classname="wixGuard">​</span></span></p>
         <p classname="font_8"><span style={{fontWeight: 'bold'}}><span style={{color: '#000000'}}>Noho Ramen's story begins with us on a mission to provide our guests with the best tonkotsu ramen and teriyaki, using only the freshest&nbsp;ingredients and the most advanced techniques developed by our master artisans.</span></span></p>
         <p classname="font_8">&nbsp;</p>
         <p classnamename="font_8"><span style={{fontWeight: 'bold'}}><span style={{color: '#000000'}}>Noho Ramen focuses on 3 elements in creating our ramen.&nbsp;</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}><span classname="wixGuard">​</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>1. Aromatic Tonkotsu Broth<br />
             2. Original Spicy Red Sauce<br />
             3.15 Second noodle rule</span><br />
           &nbsp;</p>
         <p classname="font_8"><span style={{color: '#000000'}}><span style={{fontWeight: 'bold'}}>1. AROMATIC TONKOTSU BROTH</span><br />
             Noho Ramen focuses on an authentic tasting tonkotsu broth that does not contain the usual strong aroma.</span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>Noho Ramen's all-natural tonkotsu broth is extracted from 100% pork bones. Boiling pork bones for soup normally produces a strong-smelling broth, however, the advanced technology and extraction methods of our expert artisans remove the strong aroma. Their expertise has created a savory tonkotsu soup that both smells and tastes delicious</span></p>
         <p classname="font_8"><span style={{color: '#000000'}}><span classname="wixGuard">​</span></span></p>
         <p classname="font_8"><span style={{fontWeight: 'bold'}}><span style={{color: '#000000'}}>2.ORIGINAL SPICY RED SAUCE</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>This Noho Ramen&nbsp;original sauce is made from a&nbsp;secret spicy&nbsp;pepper blend&nbsp;combining over 30 different spices and then slowly aging it to perfection. This creates a sauce that is spicy, yet contains a full of a variety of flavors that when combined with our broth and noodles, creates a richer, tastier ramen.&nbsp;</span></p>
         <p classname="font_8"><span style={{color: '#000000'}}><span classname="wixGuard">​</span></span></p>
         <p classname="font_8"><span style={{fontWeight: 'bold'}}><span style={{color: '#000000'}}>3. Noho Ramen 15 second Rule</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>Our fresh noodles are time sensitive. Once in the boiling water, they expand. Therefore, we strive to have the noodles cooked within the 15 second time and then prepared fully and served.</span></p>
         <p classname="font_8"><span style={{color: '#000000'}}><span classname="wixGuard">​</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>At Noho ramen,&nbsp;&nbsp;we serve a diversified menu, however, the star of our menu is our Hakata Ramen.&nbsp;&nbsp;</span></p>
         <p classname="font_8"><span style={{color: '#000000'}}><span classname="wixGuard">​</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>Noho Ramen offers&nbsp;a wide range of types, our pork bone broth&nbsp;as well as varying broths like seafood-based broth or vegetable -based broth. The safety and satisfaction of our customers is our priority. That is why we only use the highest-in-quality products for the creation of our ramen. Our professionals are masters of the complicated techniques involved in ramen making, and they always pursue a higher level of exquisiteness for our customers.</span></p>
         <p classname="font_8"><span style={{color: '#000000'}}><span classname="wixGuard">​</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>Our natural pork-bone soup is skimmed to perfection so that the ultimate flavor of pork is drawn out.</span></p>
         <p classname="font_8"><span style={{color: '#000000'}}><span classname="wixGuard">​</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>Additionally, our original aged "Spicy Red Sauce" is a chili-powder based blend of 30 different ingredients that is added to our ramen to bring out a more rich taste.</span></p>
         <p classname="font_8"><span style={{color: '#000000'}}><span classname="wixGuard">​</span></span></p>
         <p classname="font_8"><span style={{color: '#000000'}}>We look forward to seeing you soon!!</span></p>
         <p classname="font_8">&nbsp;</p></div>
     );
   }
 });


    </div>
  )
}
