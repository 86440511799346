import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
// import Carousel from 'nuka-carousel';
import About from './About'
import Order from './Order'
// import Menu from './Menu'
import Specials from './Pages/Specials'
import Catering from './Pages/Catering'
// import Bakery from './Bakery'
// import GiftCards from './GiftCards'
// import Spirits from './Spirits'
// import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
// import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
import AutoMenu from '../../react/AutoMenu'
export {CartBar as StickyTopBar}



export const customOrderAppPaths = ["/menu", "/order"]

export const pages = [
  {path: "/", label: "Home",  },
  {path: "menu", label: "Menu" },
  // {path: "https://nohoramenkitchenla.com/catering/", external: true, label: "Catering", component: Catering },

  {path: "/order", label: "Order" },
  {path: "/about", label: "About", component: About },
  // {path: "https://nohoramenkitchenla.com/contact/", label: "Contact", external: true },

]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  // { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-1.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-2.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-3.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-4.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-5.jpg"}
]



export function Header () {

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/romancing-the-bean-cafe/espress-dark.jpg?w=500"
      :
     "https://afag.imgix.net/romancing-the-bean-cafe/espresso-light.jpg?w=500"
    return (
      <div className="landing-page-wrapper">
        <div className="hero-banner">
          <div className="text">
            <h2>Extended Hours!!!</h2>
            {/* <p
              style={{
                fontWeight: 800,
                textAlign: 'center'
              }}
              >10% Off</p> */}
            <p>Sun-Thu 11 am - 10 pm</p>
            <p>Fri-Sat 11 am - 11 pm</p>

            {/* <p><strong>Cocktails / Beer / Wine ToGo / Delivery</strong></p> */}

            <ul>
              {/* <li>
                Order <Link to="/order">Pickup at the door</Link>
              </li> */}
              <li>
                Order <Link to="/order">Pickup or Delivery</Link>
              </li>
           


            </ul>

            {/* <p
              className="site"
              ><a href="https://nohoramenkitchenla.com/?old=true">visit old website</a></p> */}
          </div>
        </div>

        {/* <div
          style={{
            textAlign: 'center',
            marginTop: '1em'
          }}
          className="tp">
          <p>Orders over $30 get 2 free TP rolls (not the edible kind)</p>
        </div> */}

        {/* <div
          className="hero-banner">
          <div className="text">
            <h2 style={{

            }}>
            <span className="welcome">welcome to</span>
            <br/>
            <img
              style={{
                maxWidth: "350px"
              }}
              src="https://afag.imgix.net/electric-lotus/logo.png?gam=100&invert=true" alt=""/>

            </h2>

            <p>
              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
            </p>
          </div>
        </div>

        <div className="text-section">
          <div className="header">
            <div className="deco">
              <h1>
                <div className="title">Experience the Taste of India!</div>

              </h1>

            </div>
          </div>
          <div className="content">

            <p>We offer a richly-varied menu of fresh, natural ingredients, wholesome sauces and flavorful spices. All of our food is natural and freshly-prepared daily on the premises with no additives or preservatives.</p>
          </div>
        </div> */}

      <AutoMenu
        width={300}
        subMenuFilter={subMenuFilter}
        ></AutoMenu>

      </div>







    );

}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  if (tag === 'ramen-toppings') {
    return false
  }
  return true

}
