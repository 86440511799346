
import * as Components from "./Nohoramen"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "nohoramen"
}
